/*eslint-disable */
function getWidgetsEnv() {
  return process.env.NODE_ENV === 'development' // when running the UI locally
    ? 'latest'
    : globalThis.__SERENITY_DEPLOYMENT_ENV__ === 'pro' // when building the UI in CD
    ? 'stable'
    : 'latest';
}
/*eslint-enable */

module.exports = { getWidgetsEnv };
