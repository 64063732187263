module.exports = new Promise((resolve, reject) => {
  // check if remote exists on window
  if (!window['SerenityUtils']) {
    // search dom to see if remote tag exists, but might still be loading (async)
    const existingRemote = document.querySelector<HTMLScriptElement>(
      '[data-webpack="SerenityUtils"]'
    );

    // when remote is loaded..
    const onload = async () => {
      if (!window['SerenityUtils']) {
        reject(
          'Remote SerenityUtils cannot be found in global scope. Make sure the name of the remote matches your configuration.'
        );

        return;
      }

      // check if it was initialized
      if (!window['SerenityUtils'].__initialized) {
        // if share scope doesnt exist (like in webpack 4) then expect shareScope to be a manual object
        if (typeof __webpack_share_scopes__ === 'undefined') {
          if (undefined && typeof undefined !== 'string') {
            // use default share scope object passed in manually
            await window['SerenityUtils'].init(undefined.default);
          }
        } else if (typeof __webpack_share_scopes__ !== 'string') {
          const finalShareScope = !undefined ? 'default' : undefined;

          if (typeof finalShareScope === 'string') {
            // otherwise, init share scope as usual
            await window['SerenityUtils'].init(
              __webpack_share_scopes__[finalShareScope]
            );
          }
        }

        // mark remote as initialized
        window['SerenityUtils'].__initialized = true;
      }

      // resolve promise so marking remote as loaded
      resolve(window['SerenityUtils']);
    };

    if (existingRemote) {
      // if existing remote but not loaded, hook into its onload and wait for it to be ready
      existingRemote.onload = onload;
      existingRemote.onerror = reject;
      // check if remote fallback exists as param passed to function
      // TODO: should scan public config for a matching key if no override exists
    } else {
      // inject remote if a fallback exists and call the same onload function
      const script = document.createElement('script');
      // update timestamp every 1 minute
      const d = new Date();
      d.setSeconds(0);
      const time = d.setMilliseconds(0);

      script.type = 'text/javascript';
      // mark as data-webpack so runtime can track it internally
      script.setAttribute('data-webpack', 'SerenityUtils');
      script.async = true;
      script.onerror = reject;
      script.onload = onload;
      // the query string will prevent caching the remoteEntry
      const remoteUrl = 'https://ui-widgets.serenity-pro.advgo.net/utils/' + globalThis.__SERENITY_WIDGETS_ENV__ + '/remoteEntry.js';

      script.src = remoteUrl + '?' + time;

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  } else {
    // remote already instantiated, resolve
    resolve(window['SerenityUtils']);
  }
});