import { getWidgetsEnv } from './utils/env';
import packageInfo from '../package.json';

type DeploymentEnv = 'pre' | 'pro';
type SerenityEnv = 'development' | 'test' | 'production';

const hostname = window.location.href.match(
  // eslint-disable-next-line
  /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/,
)?.[1];
const domains = hostname?.split('.');
const domain = domains?.[0];
const subdomain = domains?.[1];
const tenant =
  domain === 'localhost'
    ? domain
    : domain?.match(/^(.*?)(?=-ui|-moderation-ui)/)[1];
const deploymentEnv = subdomain?.split('-').slice(-1)?.[0] as DeploymentEnv;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
globalThis.__SERENITY_TENANT__ =
  process.env.NODE_ENV === 'development' ? process.env.TENANT : tenant;
globalThis.__SERENITY_DEPLOYMENT_ENV__ =
  process.env.NODE_ENV === 'development' ? 'pre' : deploymentEnv;
globalThis.__SERENITY_ENV__ = process.env.NODE_ENV as SerenityEnv;

const widgetsEnv = getWidgetsEnv();
globalThis.__SERENITY_WIDGETS_ENV__ = widgetsEnv;
globalThis.__SERENITY_CYPRESS__ = process.env.CYPRESS_TEST === 'true';
globalThis.__SERENITY_MODERATION_UI_VERSION__ = packageInfo.version;

// Datadog must be setup only in the browser and before importing the bootstrap
if (__SERENITY_ENV__ === 'production' && !__SERENITY_CYPRESS__) {
  import('./datadog');
}

// this is just to turn this file into a module
export {};

import('./bootstrap');
